@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Barlow", sans-serif;
}

.ant-dropdown-menu  {
  padding: 10px 20px !important;
  margin-top: 5px !important;
}
.ant-dropdown-menu > li:first-child {
  margin-bottom: 5px !important;
}
.logoSec p {
  font-family: "Montserrat", sans-serif;
}
.menuBg {
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.app {
  height: 100vh;
  padding: 0 ;
  width: 100%;
}
html,
body {
  position: relative;
  height: 100%;
  width: 100%;
}

body {
  background: #eee;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 80vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}


.slick-prev:before, .slick-next:before {
  font-size: 40px !important;
}
.slick-prev, .slick-next {
  font-size: 40px !important;
  background: inherit !important;
}
.contact_bg {
  background: url("./images/contact_bg_1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.footer_title::after {
  content: "";
  display: flex;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  background: white
}
.navbar {
  position: relative !important;
  width: 100% !important;
}

/* Fixed holat uchun style */
.navbar.fixed {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1000 !important;
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .menu .menu_item, .menu .ant-space-item{
    font-size: 10px !important;
    /* margin-left: 6px !important; */
  }
   :where(.css-dev-only-do-not-override-11lehqq).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content >a, :where(.css-dev-only-do-not-override-11lehqq).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content >a {
    font-size: 10px !important;
   }
   .logo {
    font-size: 18px;
   }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  animation: scroll 20s linear infinite;
}
